import React from 'react';
import ReactDOM from 'react-dom/client';
import '@coreui/coreui/dist/css/coreui.min.css';
import App from './App';
import { GoogleOAuthProvider } from "@react-oauth/google"

import 'core-js';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>,
);