import { Flex } from '@chakra-ui/react';
import { CSpinner } from '@coreui/react-pro';
import { format } from "date-fns";
import React from 'react';

export const MainSpinner = () => {
    return <CSpinner color="primary" variant="grow" />
}
export const CenteredSpinner = () => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <MainSpinner />
        </Flex>
    );
};

export const ConvertTimeFormat = (date) => {

    const dateObject = new Date(date);
    return format(dateObject, 'yyyy-MM-dd');

}

