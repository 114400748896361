import { userActions } from "../constants"

const initialState = {
    profileDetails: "",
    profileMe: "",
    userAccountOption: [],
    currentAccountSelected: null,
}

export const userDetails = (state = initialState, action) => {
    switch (action.type) {
        case userActions.USER_PROFILE_DETAILS:
            return {
                ...state,
                profileDetails: action.payload,
            }
        case userActions.USER_AVATAR:
            return {
                ...state,
                profileMe: action.payload,
            }
        case userActions.USER_ACCOUNT_OPTIONS:
            return {
                ...state,
                userAccountOption: action.payload,
            }
        case userActions.CURRENT_ACCOUNT_SELECTED:
            return {
                ...state,
                currentAccountSelected: action.payload,
            }
        default:
            return state
    }
}